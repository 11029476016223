<template>
	<div>
		<Form
			:id="id"
			:is-update="isUpdate"
			@createOrder="createOrder"
			@updateOrder="updateOrder"
			@cancelForm="cancelForm"
			@submit="updateForm"
			@cancel="cancel"
		/>
		<CustomModal
			v-if="isCancelFormModal"
			:title="$t('FormMessages.cancel.header')"
			:submit-text="$t('Button.Confirm')"
			:cancel-text="$t('Button.Cancel')"
			@submit-modal="sure"
			@cancel-modal="cancelModal"
		>
			<template #content>
				<ValidationObserver ref="form" v-slot="{ handleSubmit }">
					<form @submit.prevent="handleSubmit(sure)">
						<div class="custom">
							<div class="custom__input">
								<div class="form-group position-relative mb-0">
									<ValidationProvider
										v-slot="{ classes, errors }"
										name="form_reason"
										mode="lazy"
										:rules="`requiredExport|max:${CANCEL_REASON}`"
									>
										<div class="form-group row">
											<label
												for="reason"
												class="col-12 col-xl-12 col-lg-12 col-md-12 col-form-label"
												>{{ $t("FormsField.reason") }}</label
											>
											<div :class="['col-12 col-xl-12 col-lg-12 col-md-12', classes]">
												<textarea
													id="description"
													v-model.trim="reason"
													type="text"
													rows="6"
													class="form-control remove-resize"
													:placeholder="$t('FormsField.reason')"
													autocomplete="description"
													:maxlength="CANCEL_REASON"
												/>
												<div class="error-mess">
													{{ errors[0] }}
												</div>
											</div>
										</div>
									</ValidationProvider>
								</div>
							</div>
						</div>
					</form>
				</ValidationObserver>
			</template>
		</CustomModal>
		<CustomModal
			v-if="isLocked"
			:submit-text="$t('Button.OK')"
			:is-show-cancel="false"
			@submit-modal="confirm"
			@cancel-modal="confirm"
		>
			<template #content>
				{{ lockError }}
			</template>
		</CustomModal>
	</div>
</template>

<script>
import {
	GET_FORM_DETAIL,
	UPDATE_FORM,
	CANCEL_FORM,
	LOCK_FORM,
	UNLOCK_FORM,
	UPDATE_FORM_WEB,
} from "../store/action-types"
import { RESET_FILTERS_FORM_LIST, SET } from "../store/mutation-types"
import { RESET_CUSTOMER_FILTERS } from "@/modules/customers/store/mutation-types"
import { RESET_ORDER_FILTERS } from "@/modules/orders/store/mutation-types"
import { createNamespacedHelpers } from "vuex"
const { mapActions, mapState, mapMutations } = createNamespacedHelpers("forms")
import { FORM_TYPE_CLICK, CANCEL_REASON } from "@/shared/plugins/constants"

export default {
	name: "UpdateForm",
	components: {
		Form: () => import("../components/Form"),
	},
	props: {
		id: {
			type: [String, Number],
			default: null,
		},
		isUpdate: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			CANCEL_REASON,
			reason: null,
			lock: null,
			isLocked: false,
		}
	},
	computed: {
		...mapState(["isCancelFormModal", "originalId", "lockError"]),
	},
	async created() {
		await this.lockForm()
		if (!this.lockError) {
			this.lock = setInterval(async () => {
				await this.lockForm()
			}, process.env.VUE_APP_INTERVAL_LOCK)
			await this.getFormDetail()
		} else {
			this.isLocked = true
			this.$store.commit("set", ["modalShow", true])
		}
	},
	mounted() {
		const body = document.getElementsByTagName("body")[0]
		body.scrollTop = 0
	},
	async beforeDestroy() {
		this.RESET_FILTERS_FORM_LIST()
		this.$store.commit(`customers/${RESET_CUSTOMER_FILTERS}`)
		this.$store.commit(`orders/${RESET_ORDER_FILTERS}`)
		await this.unlockForm()
		clearInterval(this.lock)
		this.isLocked = false
	},
	methods: {
		...mapActions({
			GET_FORM_DETAIL,
			UPDATE_FORM,
			CANCEL_FORM,
			LOCK_FORM,
			UNLOCK_FORM,
			UPDATE_FORM_WEB,
		}),
		...mapMutations({ RESET_FILTERS_FORM_LIST, SET }),
		async getFormDetail() {
			await this.GET_FORM_DETAIL({ id: this.id })
		},
		updateForm: async function (params) {
			this.showLoading()
			let type = null
			if (params.typeClick) {
				type = params.typeClick
				delete params.typeClick
			}

			if (params.files && params.files.length) {
				const files = params.files.map(el => el.file)
				// upload file
				const response = await this.$store.dispatch("uploadFile", files)
				if (response.ok) {
					delete params.files
					params["tokens"] = response.tokens
				}
			}
			// update form
			const res = await this.UPDATE_FORM(params)
			if (res.ok) {
				switch (type) {
					case FORM_TYPE_CLICK.UPDATE:
						this.updateOrder()
						break
					case FORM_TYPE_CLICK.CREATE:
						this.createOrder()
						break
					default:
						this.gotoList()
						break
				}
			}
			this.hideLoading()
		},
		cancelForm() {
			this.SET(["isCancelFormModal", true])
			this.$store.commit("set", ["modalShow", true])
		},
		cancelModal() {
			this.reason = null
			this.SET(["isCancelFormModal", false])
		},
		gotoList() {
			this.$router.push({ name: "FormManagement" })
		},
		createOrder() {
			this.$router.push({
				path: "/orders/create",
				query: { formID: this.id },
			})
		},
		updateOrder() {
			this.$router.push({
				path: "/orders/create",
				query: {
					redirect: this.$route.name,
					formID: this.id,
					update_order: true,
					original_order: this.originalId,
				},
			})
		},
		async sure() {
			const isValid = await this.$refs.form.validate()
			if (isValid) {
				const params = {
					id: parseInt(this.id),
					reason: this.reason,
				}
				await this.CANCEL_FORM(params).then(res => {
					if (res.ok) {
						this.$router.push({ name: "FormManagement" })
					}
				})
				this.SET(["isCancelFormModal", false])
				this.$store.commit("set", ["modalShow", false])
				this.reason = null
			}
		},
		async lockForm() {
			const params = {
				formId: this.id,
			}
			await this.LOCK_FORM(params)
		},
		async unlockForm() {
			if (!this.isLocked) {
				const params = {
					formId: this.id,
				}
				await this.UNLOCK_FORM(params)
			}
		},
		confirm() {
			this.$store.commit("set", ["modalShow", false])
			this.$router.push(`/forms`)
		},
		cancel() {
			this.$router.go(-1)
		},
	},
}
</script>
<style lang="scss" scoped>
.custom__input {
	width: 100%;
}
</style>
